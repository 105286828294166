import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';

import CircleSpinner from './CircleSpinner.js';
import styles from './LabeledCircleSpinner.module.scss';

export default function LabeledCircleSpinner({
  className,
  label = 'Einen Moment bitte …',
}) {
  return (
    <div className={classNames(styles.container, className)}>
      <CircleSpinner
        classNameOutline={styles.spinner_outline}
        classNameSpinner={styles.spinner_spinner}
      />
      <span className={text_styles.body2}>{label}</span>
    </div>
  );
}
