import {useState} from 'react';
import {useSelector} from 'react-redux';
import {BANKING_FEATURE, MANAGER_PATHS} from 'shared/constants.js';
import Button from 'shared/components/Button.js';
import {Switch, Route, useHistory} from 'react-router-dom';

import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import {showDialog} from '../../effects.js';
import Pagination from '../../components/Pagination.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import {useFetchRefresh} from '../../hooks.js';

import CreateBankingAccountDialog from './CreateBankingAccountDialog.js';
import BankingAccountDialog from './BankingAccountDialog.js';
import BankingAccountsPlaceholder from './BankingAccountsPlaceholder.js';
import BankingAccountsTable from './BankingAccountsTable.js';
import styles from './BankingAccountsScreen.module.scss';
import {ReactComponent as DownloadSvg} from './icon-download.svg';

const per_page = 10;
const default_sort_column = 'created_at';
const default_sort_direction = 'DESC';

export default function BankingAccountsScreen() {
  const history = useHistory();
  const {refresh} = useSelector((state) => state.banking_accounts);
  const {features} = useSelector((state) => state.user.account);
  const [search_query, setSearchQuery] = useState('');
  const [sort_column, setSortColumn] = useState(default_sort_column);
  const [sort_direction, setSortDirection] = useState(default_sort_direction);

  const {loading, rows, total, offset, setOffset} = useFetchRefresh({
    method: 'getBankingAccounts',
    search_query,
    per_page,
    refresh,
    sort_column,
    sort_direction,
  });

  function orderBy(field) {
    if (sort_column === field) {
      sort_direction === 'DESC'
        ? setSortDirection('ASC')
        : setSortDirection('DESC');
    } else {
      setSortColumn(field);
      setSortDirection('ASC');
    }
  }

  function resetTable() {
    setSortColumn(default_sort_column);
    setSortDirection(default_sort_direction);
    setOffset(0);
  }

  const has_results_and_is_eligible =
    rows.length > 0 &&
    features.banking === BANKING_FEATURE.enabled_and_eligible;

  return (
    <Layout
      button={
        <Buttons
          resetTable={resetTable}
          features={features}
          has_results_and_is_eligible={has_results_and_is_eligible}
          history={history}
        />
      }
      search={
        features.banking === BANKING_FEATURE.enabled_and_eligible && (
          <SearchInput onValue={setSearchQuery} />
        )
      }>
      <Switch>
        <Route path={MANAGER_PATHS.CreateBankingAccountDialog}>
          <CreateBankingAccountDialog />
        </Route>
        <Route path={`${MANAGER_PATHS.BankingAccountsScreen}/:id`}>
          <BankingAccountDialog />
        </Route>
      </Switch>
      <div className={styles.content}>
        {has_results_and_is_eligible ? (
          <BankingAccountsTable
            onOpen={(banking_account) =>
              history.push(
                `${MANAGER_PATHS.BankingAccountsScreen}/${banking_account.id}`,
              )
            }
            banking_accounts={rows}
            loading={loading}
            orderBy={orderBy}
            sort_column={sort_column}
            sort_direction={sort_direction}
          />
        ) : (
          <BankingAccountsPlaceholder
            search_query={search_query}
            loading={loading}
          />
        )}
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={per_page}
          />
        )}
      </div>
    </Layout>
  );
}

function Buttons({resetTable, features, has_results_and_is_eligible, history}) {
  return (
    <div className={styles.button_container}>
      <ButtonAdd
        title="Konto erstellen"
        onClick={() => {
          resetTable();
          history.push(MANAGER_PATHS.CreateBankingAccountDialog);
        }}
        disabled={features.banking === BANKING_FEATURE.enabled_and_not_eligible}
      />
      {has_results_and_is_eligible && (
        <Button
          title="Transaktionen herunterladen"
          Icon={DownloadSvg}
          onClick={() => {
            showDialog('banking_download_statements');
          }}
          className={styles.download_transactions}
          textClassName={styles.download_transactions_text}
        />
      )}
    </div>
  );
}
