import React from 'react';
import {useTable} from 'react-table';
import classNames from 'classnames';
import {formatDate, formatIban, formatPrice} from 'shared/utils.js';
import {Dropdown} from 'react-bootstrap';
import text_styles from 'shared/styles/text_styles.module.scss';
import Icon from 'shared/components/Icon.js';

import {ReactComponent as MoreVerticalSvg} from '../../assets/more_vertical.svg';
import TableHeaderButton from '../../components/table_header_button/TableHeaderButton.js';

import styles from './RentCollectionTable.module.scss';

export default function RentCollectionTable({
  rental_contracts_with_viban,
  onOpen,
  orderBy,
  sort_column,
  sort_direction,
  onOpenRentCollection,
}) {
  const {getTableProps, headerGroups, getTableBodyProps, rows, prepareRow} =
    useTable({columns, data: rental_contracts_with_viban});

  return (
    <table className={styles.table} {...getTableProps()}>
      <thead className={styles.thead}>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th
                key={idx}
                className={classNames(styles.th, styles[column.id])}
                {...column.getHeaderProps()}>
                {column.sort ? (
                  <TableHeaderButton
                    orderBy={orderBy}
                    column={column}
                    sort_column={sort_column}
                    sort_direction={sort_direction}
                  />
                ) : (
                  <div className={styles.header}>{column.render('Header')}</div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          prepareRow(row);
          return (
            <RentCollectionRow
              key={idx}
              row={row}
              onOpenRentCollection={onOpenRentCollection}
              onOpen={onOpen}
            />
          );
        })}
      </tbody>
    </table>
  );
}

function Menu({rental_contract, onOpenRentCollection}) {
  return (
    <Dropdown>
      <Dropdown.Toggle as="button">
        <Icon>
          <MoreVerticalSvg />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item
          as="button"
          onClick={() => onOpenRentCollection(rental_contract)}>
          Mieter konfigurieren
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function RentCollectionRow({row, onOpenRentCollection, onOpen}) {
  return (
    <tr className={styles.tr} {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === 'actions') {
          return (
            <td {...cell.getCellProps()}>
              <Menu
                rental_contract={row.original}
                onOpenRentCollection={onOpenRentCollection}
              />
            </td>
          );
        }
        return (
          <td
            {...cell.getCellProps()}
            className={classNames(styles.pointer, styles[cell.column.id])}
            onClick={() => onOpen(row.original)}>
            <div
              className={classNames(
                text_styles[cell.column.text_styles],
                styles.cell,
              )}>
              <span> {cell.render('Cell')}</span>
            </div>
          </td>
        );
      })}
    </tr>
  );
}

export const columns = [
  {
    id: 'given_reference',
    Header: 'Mieteinheit',
    accessor: ({tenant_display_name, given_reference}) => {
      return (
        <div>
          <p className={text_styles.body1_bold_left}>{tenant_display_name}</p>
          <p className={text_styles.body1_italic}>{given_reference || '–'}</p>
        </div>
      );
    },
    text_styles: 'body1_bold_left',
    sort: 'tenant_display_name',
  },
  {
    id: 'viban',
    Header: 'Virtuelle IBAN',
    accessor: 'viban',
    Cell: ({value}) => formatIban(value),
    text_styles: 'body2',
    sort: 'viban',
  },
  {
    id: 'warm_rent_cents',
    Header: 'Warmmiete',
    accessor: 'warm_rent_cents',
    Cell: ({value}) => {
      return value ? formatPrice(value) : '–';
    },
    text_styles: 'body2',
    sort: 'warm_rent_cents',
  },
  {
    id: 'created_at',
    Header: 'Erstellt',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
  {
    id: 'actions',
    Header: '',
    accessor: 'actions',
    text_styles: '',
  },
];
