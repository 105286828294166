import React from 'react';
import {useTable} from 'react-table';
import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames';
import {formatDate, formatIban} from 'shared/utils.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Icon from 'shared/components/Icon.js';
import {MANAGER_BANKING_STATUS, MANAGER_PATHS} from 'shared/constants.js';
import {useHistory} from 'react-router-dom';

import {ReactComponent as MoreVerticalSvg} from '../../assets/more_vertical.svg';
import TableHeaderButton from '../../components/table_header_button/TableHeaderButton.js';

import styles from './BankingAccountsTable.module.scss';
import ManagerStatus from './ManagerStatus.js';

export default function BankingAccountsTable({
  onOpen,
  banking_accounts,
  orderBy,
  sort_column,
  sort_direction,
}) {
  const {getTableProps, headerGroups, getTableBodyProps, rows, prepareRow} =
    useTable({columns, data: banking_accounts});

  return (
    <table className={styles.table} {...getTableProps()}>
      <thead className={styles.thead}>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th
                key={idx}
                className={classNames(styles.th, styles[column.id])}
                {...column.getHeaderProps()}>
                {column.sort ? (
                  <TableHeaderButton
                    orderBy={orderBy}
                    column={column}
                    sort_column={sort_column}
                    sort_direction={sort_direction}
                  />
                ) : (
                  <div className={styles.header}>{column.render('Header')}</div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          prepareRow(row);
          return <BankingAccountRow row={row} key={idx} onOpen={onOpen} />;
        })}
      </tbody>
    </table>
  );
}

function Menu({banking_account}) {
  const history = useHistory();

  return (
    <Dropdown>
      <Dropdown.Toggle as="button">
        <Icon>
          <MoreVerticalSvg />
        </Icon>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        <Dropdown.Item
          href={banking_account.swan_url}
          target="_blank"
          rel="noreferrer"
          className={
            (!banking_account.swan_url ||
              banking_account.manager_status ===
                MANAGER_BANKING_STATUS['200_access_not_granted']) &&
            'disabled'
          }>
          Transaktionen anzeigen
        </Dropdown.Item>

        <Dropdown.Item
          href={banking_account.swan_url}
          target="_blank"
          rel="noreferrer"
          className={
            (!banking_account.swan_url ||
              banking_account.manager_status ===
                MANAGER_BANKING_STATUS['200_access_not_granted']) &&
            'disabled'
          }>
          Zahlung veranlassen
        </Dropdown.Item>

        <Dropdown.Item
          as="button"
          onClick={() =>
            history.push(
              `${MANAGER_PATHS.BankingAccountsScreen}/${banking_account.id}`,
            )
          }>
          Konto bearbeiten
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function BankingAccountRow({row, onOpen}) {
  return (
    <tr className={styles.tr} {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === 'actions') {
          return (
            <td {...cell.getCellProps()}>
              <Menu banking_account={row.original} />
            </td>
          );
        }
        return (
          // getCellProps() has a key
          <td
            {...cell.getCellProps()}
            onClick={() => onOpen(row.original)}
            className={classNames(styles.pointer, styles[cell.column.id])}>
            <div
              className={classNames(
                text_styles[cell.column.text_styles],
                styles.cell,
              )}>
              <span> {cell.render('Cell')}</span>
            </div>
          </td>
        );
      })}
    </tr>
  );
}

export const columns = [
  {
    id: 'display_name',
    Header: 'Konto',
    accessor: ({property_owner_display_name, description}) => {
      return (
        <div>
          <p className={text_styles.body1_bold_left}>
            {property_owner_display_name}
          </p>
          <p className={text_styles.body1_italic}>{description || '–'}</p>
        </div>
      );
    },
    text_styles: 'body1_bold_left',
    sort: 'property_owners.display_name',
  },
  {
    id: 'manager_status',
    Header: 'Status',
    accessor: ({manager_status}) => {
      return <ManagerStatus manager_status={manager_status} />;
    },
    sort: 'manager_status',
  },
  {
    id: 'iban',
    Header: 'IBAN',
    accessor: ({iban}) => (iban ? formatIban(iban) : '–'),
    text_styles: 'body2',
    sort: 'iban',
  },
  {
    id: 'created_at',
    Header: 'Erstellt',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
  {id: 'actions', Header: '', accessor: 'actions', text_styles: ''},
];
