import Icon from 'shared/components/Icon.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {MANAGER_BANKING_STATUS} from 'shared/constants.js';
import classNames from 'classnames';

import styles from './ManagerStatus.module.scss';
import {ReactComponent as WarningSvg} from './icon-warning.svg';
import {ReactComponent as HourglassSvg} from './icon-hourglass.svg';

export default function ManagerStatus({manager_status}) {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>{statusIcon(manager_status)}</div>
      <div className={classNames(text_styles.body2, styles.text)}>
        {translateStatus(manager_status)}
      </div>
    </div>
  );
}

export function statusIcon(manager_status) {
  switch (MANAGER_BANKING_STATUS[manager_status]) {
    case MANAGER_BANKING_STATUS['100_access_granted']: {
      return null;
    }
    case MANAGER_BANKING_STATUS['200_access_not_granted']: {
      return (
        <Icon>
          <WarningSvg />
        </Icon>
      );
    }
    case MANAGER_BANKING_STATUS['300_account_is_created']: {
      return (
        <Icon>
          <HourglassSvg />
        </Icon>
      );
    }
    case MANAGER_BANKING_STATUS['400_account_request_sent']: {
      return (
        <Icon>
          <HourglassSvg />
        </Icon>
      );
    }
    default: {
      return null;
    }
  }
}

function translateStatus(manager_status) {
  switch (manager_status) {
    case MANAGER_BANKING_STATUS['100_access_granted']: {
      return 'Zugriff erteilt';
    }
    case MANAGER_BANKING_STATUS['200_access_not_granted']: {
      return 'Zugriff nicht erteilt';
    }
    case MANAGER_BANKING_STATUS['300_account_is_created']: {
      return 'Konto wird erstellt';
    }
    case MANAGER_BANKING_STATUS['400_account_request_sent']: {
      return 'Konto Anfrage versendet';
    }
  }
}
