import React from 'react';
import text_styles from 'shared/styles/text_styles.module.scss';
import Input from 'shared/components/Input.js';
import {useForm} from 'react-hook-form';
import Button from 'shared/components/Button.js';
import {BAD_REQUEST} from 'shared/api.js';
import {setFormErrors} from 'shared/effects.js';
import classNames from 'classnames';
import {useNotify} from 'shared/NotifyProvider.js';

import Dialog from '../../components/dialog/Dialog.js';
import {closeDialog, handleError, showDialog} from '../../effects.js';
import {
  createBankingAccount,
  updateBankingPropertyOwner,
} from '../../actions.js';

import styles from './PropertyOwnerEmailDialog.module.scss';

export default function PropertyOwnerEmailDialog({
  property_owner_id,
  property_owner_display_name,
  description,
  ...props
}) {
  const {notify} = useNotify();
  const {
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({mode: 'onChange'});

  if (!property_owner_id) return null;

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, property_owner_id, setError, description, notify}),
  );

  return (
    <Dialog
      title="Bitte vervollständigen Sie die Angaben"
      onExited={() => closeDialog('property_owner_email')}
      className={styles.container}
      {...props}>
      <div className={styles.content}>
        <Input
          label="Email"
          placeholder="z.B. max@mustermann.de"
          error={errors.email_address?.message}
          {...register('email_address')}
        />
        <div className={classNames(text_styles.body1_bold_left, styles.text)}>
          Um {property_owner_display_name} zur Eröffnung eines Kontos
          einzuladen, benötigen wir eine E-Mail-Adresse.
        </div>
      </div>
      <Button
        type="submit"
        title="Angaben ergänzen & Einladung senden"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </Dialog>
  );
}

async function proceed({
  fields,
  property_owner_id,
  setError,
  description,
  notify,
}) {
  try {
    await updateBankingPropertyOwner({
      id: property_owner_id,
      email_address: fields.email_address,
    });
    await createBankingAccount({property_owner_id, description});
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  closeDialog('property_owner_email');
  showDialog('create_banking_account_success');
  notify({
    text: 'Die Einladung wurde versendet',
  });
}
