import {useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export default function useSwanSearchParams() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location.search) return;
    const search_params = new URLSearchParams(history.location.search);
    if (
      search_params.get('code') &&
      search_params.get('scope') &&
      search_params.get('state')
    ) {
      const swan_state = {
        code: search_params.get('code'),
        scope: search_params.get('scope'),
        state: search_params.get('state'),
      };

      search_params.delete('code');
      search_params.delete('scope');
      search_params.delete('state');

      history.push({
        ...location,
        search: search_params.toString(),
        state: {
          swan: swan_state,
        },
      });
    }
  }, [history, location]);

  const swan_state = useMemo(
    () => location.state?.swan || null,
    [location.state?.swan],
  );

  return swan_state;
}
