import classNames from 'classnames/bind';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import Dialog from '../../components/dialog/Dialog.js';
import {closeDialog} from '../../effects.js';

import next_1 from './account-next1@2x.png';
import next_2 from './account-next2@2x.png';
import styles from './CreateBankingAccountSuccessDialog.module.scss';

export default function CreateBankingAccountSuccessDialog({...props}) {
  const footer = (
    <div className={styles.footer}>
      <Button
        title="Fertig"
        onClick={() => closeDialog('create_banking_account_success')}
      />
    </div>
  );

  return (
    <Dialog
      title="Nächste Schritte"
      footer={footer}
      className={styles.wrapper}
      {...props}>
      <div className={styles.info_wrapper}>
        <div>
          <div className={styles.next_image}>
            <img src={next_1} alt="information" width="266px" height="185px" />
          </div>
          <div className={styles.info}>
            <div
              className={classNames(
                text_styles.body1_bold_left,
                styles.info_title,
              )}>
              Wir senden dem Eigentümer einen Link per E-Mail zur Kontoeröffnung
            </div>
            <div className={text_styles.caption_left}>
              Der Eigentümer kann online in wenigen Schritten das Konto eröffnen
              und Ihnen eine Vollmacht ausstellen.
            </div>
          </div>
        </div>
        <div>
          <div className={styles.next_image}>
            <img
              src={next_2}
              alt="information"
              className={styles.next_image}
              width=" 266px"
              height="185px"
            />
          </div>
          <div className={styles.info}>
            <div
              className={classNames(
                text_styles.body1_bold_left,
                styles.info_title,
              )}>
              Wir informieren Sie sobald Sie Zugriff auf das Konto haben
            </div>
            <div className={text_styles.caption_left}>
              Über die Getmomo-Plattform haben Sie Zugriff auf das
              Eigentümerkonto und können virtuelle Konten eröffnen und Zahlungen
              vornehmen.
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
