import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {RPC} from 'shared/api.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import LabeledCircleSpinner from 'shared/components/LabeledCircleSpinner.js';
import SwanLayout from 'shared/components/SwanLayout/SwanLayout.js';
import useSwanSearchParams from 'shared/hooks/useSwanSearchParams.js';
import classNames from 'classnames';
import {MANAGER_PATHS} from 'shared/constants.js';

import {handleError} from '../../effects.js';

import styles from './AcceptBankingAccountMembershipScreen.module.scss';

export default function AcceptBankingAccountMembershipScreen() {
  const swan_params = useSwanSearchParams();
  const history = useHistory();

  useEffect(() => {
    if (!swan_params) return;
    acceptBankingAccountMembership({swan_params, history});
  }, [swan_params, history]);

  return (
    <SwanLayout>
      <div className={styles.container}>
        <p
          className={classNames(
            text_styles.body1_bold_centered,
            styles.message,
          )}>
          Wir fügen Sie als Mitglied hinzu …
        </p>
        <LabeledCircleSpinner />
      </div>
    </SwanLayout>
  );
}

async function acceptBankingAccountMembership({swan_params, history}) {
  if (!swan_params) {
    return;
  }
  const {code: swan_user_auth_code, state: token} = swan_params;

  try {
    await RPC('acceptBankingAccountMembership', {
      token,
      swan_user_auth_code,
    });
  } catch (err) {
    handleError(err);
    return;
  }

  history.push(MANAGER_PATHS.BankingAccountsScreen);
}
