import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import SeparatorLine from 'shared/components/SeparatorLine.js';
import PriceInput from 'shared/components/PriceInput.js';
import {Controller} from 'react-hook-form';
import {formatPrice, formatIban} from 'shared/utils.js';
import Input from 'shared/components/Input.js';

import TextFieldWithCopy from './TextFieldWithCopy.js';
import styles from './BasicInformationSection.module.scss';

export default function BasicInformationSection({
  rent_collection,
  errors,
  control,
  register,
}) {
  const warm_rent_cents_provided = rent_collection?.warm_rent_cents > 0;

  return (
    <div>
      <Input
        label="Hauptmieter"
        value={rent_collection?.tenant_display_name}
        readOnly
      />
      <div>
        <p className={text_styles.caption_left}>Konto</p>
        <div className={styles.account}>
          <p className={text_styles.body2_left}>
            {`${formatIban(rent_collection?.banking_account?.iban)} (${
              rent_collection?.banking_account?.property_owner_display_name
            })`}
          </p>
          <p className={text_styles.body2_left}>
            {rent_collection?.banking_account?.description}
          </p>
        </div>
      </div>

      <Input
        defaultValue={rent_collection?.given_reference || ''}
        label="Beschreibung Wohneinheit"
        error={errors.given_reference?.message}
        {...register('given_reference')}
      />

      <Controller
        control={control}
        name="warm_rent_cents"
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            defaultValue={
              warm_rent_cents_provided
                ? formatPrice(rent_collection?.warm_rent_cents)
                : '...'
            }
            name={name}
            label="Warmmiete (€)"
            placeholder="..."
            error={errors.warm_rent_cents?.message}
          />
        )}
      />
      {!warm_rent_cents_provided && (
        <p
          className={classNames(text_styles.body1_bold_left, styles.warm_rent)}>
          Tracking wird möglich sobald die Warmmiete eingetragen wurde
        </p>
      )}

      <SeparatorLine />

      <div className={styles.row}>
        <TextFieldWithCopy
          label="Virtuelle IBAN:"
          text={formatIban(rent_collection?.viban)}
          copyLabel="Virtuelle IBAN"
        />
        <TextFieldWithCopy
          label="BIC:"
          text={rent_collection?.banking_account?.bic}
          copyLabel="BIC"
        />
      </div>

      <TextFieldWithCopy
        label="Zahlungsempfänger:"
        text={rent_collection?.banking_account?.property_owner_display_name}
        copyLabel="Zahlungsempfänger"
      />

      <SeparatorLine />

      <p className={classNames(text_styles.body2_left, styles.info)}>
        Die für dieses Mietverhältnis erstellte virtuelle IBAN kann für alle
        Zahlungseingänge dieser Partei verwendet werden.
      </p>
    </div>
  );
}
