import React, {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import PhoneNumberInput from 'shared/components/phoneNumberInput/PhoneNumberInput.js';
import Input from 'shared/components/Input.js';
import {BAD_REQUEST} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import {setFormErrors} from 'shared/effects.js';
import Select from 'shared/components/Select';
import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';
import IconButton from 'shared/components/IconButton.js';
import {useParams, useHistory} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';
import {MANAGER_PATHS} from 'shared/constants.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {updatePropertyOwner} from '../../actions.js';
import {
  handleError,
  alert,
  confirm,
  openTenantReport,
  confirmClose,
} from '../../effects.js';
import {ReactComponent as TrashSvg} from '../../assets/trash.svg';
import {
  country_select_options,
  gender_select_options,
} from '../../lib/utils.js';
import {useResources} from '../../hooks.js';

import styles from './PropertyOwnerDialog.module.scss';
import CoownerFormSection from './CoownerFormSection.js';

export default function PropertyOwnerDialog({onDeletePropertyOwner, ...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const history = useHistory();
  const [property_owner] = useResources('getPropertyOwners', id);
  const {notify} = useNotify();

  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    reset(property_owner);
  }, [property_owner, reset]);

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, property_owner, setError, setVisible, notify}),
  );

  function TrashElement() {
    if (!property_owner) return null;
    const disable_delete =
      property_owner.number_of_active_deposit_guarantees > 0 ||
      property_owner.number_of_active_banking_accounts > 0;

    async function onDelete() {
      if (property_owner.number_of_active_deposit_guarantees > 0) {
        alert({
          title:
            'Sie können den Eigentümer erst löschen, wenn keine Mieter mehr verknüpft sind',
          text: 'Sie können die Verknüpfung aufheben durch Freigeben oder Löschen des Mieters. Bei Fragen können Sie sich an unseren Support wenden.',
        });
      } else if (property_owner.number_of_active_banking_accounts > 0) {
        alert({
          title: 'Es gibt noch aktive Momo-Konten',
          text: 'Eigentümer können gelöscht werden, sobald alle zugehörigen Momo-Konten geschlossen sind. \n \n Um die Schließung eines Kontos zu beantragen, wenden Sie sich bitte an:',
          email: 'partnersupport@getmomo.de',
        });
      } else {
        onDeletePropertyOwner({property_owner, notify}).then((didDelete) => {
          if (didDelete) history.push(MANAGER_PATHS.PropertyOwnersScreen);
        });
      }
    }

    return (
      <IconButton onClick={onDelete} disabled={disable_delete}>
        <TrashSvg />
      </IconButton>
    );
  }

  const footer = (
    <div className={styles.footer}>
      <Button
        title="Speichern"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
      <Button
        title="Bericht generieren"
        onClick={() => openTenantReport(property_owner)}
        className={styles.report_button}
        disabled={property_owner?.number_of_active_deposit_guarantees <= 0}
      />
      <div className={styles.status_row}>
        <div className={text_styles.body1_bold_left}>Verknüpfte Mieter:</div>
        <div className={text_styles.body2}>
          {property_owner?.number_of_active_deposit_guarantees}
        </div>
      </div>
    </div>
  );

  const title = `Eigentümer ${
    property_owner?.type === 'company' ? '(Firma)' : '(Privatperson)'
  } `;

  return (
    <Dialog
      title={title}
      show={visible}
      className={styles.dialog}
      footer={footer}
      additionalIcons={[TrashElement]}
      onHide={() => confirmClose({isDirty})}
      onExited={() => history.push(MANAGER_PATHS.PropertyOwnersScreen)}
      {...props}>
      {property_owner?.type === 'company' && (
        <CompanyForm
          property_owner={property_owner}
          errors={errors}
          register={register}
        />
      )}

      {property_owner?.type === 'individual' && (
        <IndividualForm
          property_owner={property_owner}
          errors={errors}
          register={register}
          watch={watch}
          setValue={setValue}
        />
      )}

      <div className={styles.separator} />
      <div className={classNames(text_styles.body1_bold_left, styles.title)}>
        Optional Angaben:
      </div>

      {property_owner?.type === 'company' && (
        <div className={styles.row}>
          <Input
            defaultValue={property_owner?.first_name || ''}
            label="Ansprechpartner Vorname"
            error={errors.first_name?.message}
            className={styles.margin_right}
            {...register('first_name')}
          />
          <Input
            defaultValue={property_owner?.last_name || ''}
            label="Ansprechpartner Nachname"
            error={errors.last_name?.message}
            {...register('last_name')}
          />
        </div>
      )}

      <div className={styles.row}>
        <Controller
          control={control}
          name="phone_number"
          defaultValue={property_owner?.phone_number || ''}
          render={({field: {value, onChange, name}}) => (
            <PhoneNumberInput
              value={value}
              onChange={onChange}
              label="Telefonnummer"
              error={errors[name]?.message}
              className={classNames(styles.margin_right, styles.last_row)}
            />
          )}
        />
        <Input
          defaultValue={property_owner?.email_address || ''}
          label="E-mail"
          error={errors.email_address?.message}
          className={styles.last_row}
          {...register('email_address')}
        />
      </div>
    </Dialog>
  );
}

async function proceed({fields, property_owner, setError, setVisible, notify}) {
  if (
    !(await confirm({
      title: 'Möchten Sie die Eigentümerdaten wirklich ändern?',
      text: 'Dadurch wird der Eigentümer bei allen verknüpften Mietern aktualisiert!',
      proceed_label: 'Ja, jetzt aktualisieren',
    }))
  ) {
    return;
  }

  try {
    await updatePropertyOwner({
      ...fields,
      id: property_owner.id,
      type: property_owner.type,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  notify({
    text: 'Die Änderungen wurden gespeichert',
  });

  setVisible(false);
}

function CompanyForm({property_owner, errors, register}) {
  return (
    <>
      <div className={classNames(text_styles.body1_bold_left, styles.title)}>
        Angaben zur Firma
      </div>

      <Input
        defaultValue={property_owner.company_name || ''}
        placeholder="z. B. Schmitt Immobilien GmbH"
        label="Firma und Rechtsform"
        error={errors.company_name?.message}
        {...register('company_name')}
      />

      <div className={styles.row}>
        <Input
          defaultValue={property_owner.street_name || ''}
          label="Straße"
          error={errors.street_name?.message}
          className={styles.margin_right}
          {...register('street_name')}
        />
        <Input
          defaultValue={property_owner.street_number || ''}
          label="Hausnummer"
          error={errors.street_number?.message}
          {...register('street_number')}
        />
      </div>

      <div className={styles.row}>
        <Input
          defaultValue={property_owner.postal_code || ''}
          label="PLZ"
          error={errors.postal_code?.message}
          className={styles.margin_right}
          {...register('postal_code')}
        />
        <Input
          defaultValue={property_owner.region || ''}
          label="Ort"
          error={errors.region?.message}
          {...register('region')}
        />
      </div>

      <Select
        label="Land"
        options={country_select_options}
        defaultValue={property_owner.country || 'DE'}
        {...register('country')}
      />
    </>
  );
}

function IndividualForm({property_owner, errors, register, watch, setValue}) {
  return (
    <>
      <div className={styles.row}>
        <Input
          defaultValue={property_owner.first_name || ''}
          label="Vorname"
          error={errors.first_name?.message}
          className={styles.margin_right}
          {...register('first_name')}
        />
        <Input
          defaultValue={property_owner.last_name || ''}
          label="Nachname"
          error={errors.last_name?.message}
          {...register('last_name')}
        />
      </div>

      <div className={styles.row}>
        <Input
          defaultValue={property_owner.street_name || ''}
          label="Straße"
          error={errors.street_name?.message}
          className={styles.margin_right}
          {...register('street_name')}
        />
        <Input
          defaultValue={property_owner.street_number || ''}
          label="Hausnummer"
          error={errors.street_number?.message}
          {...register('street_number')}
        />
      </div>

      <div className={styles.row}>
        <Input
          defaultValue={property_owner.postal_code || ''}
          label="PLZ"
          error={errors.postal_code?.message}
          className={styles.margin_right}
          {...register('postal_code')}
        />
        <Input
          defaultValue={property_owner.region || ''}
          label="Ort"
          error={errors.region?.message}
          {...register('region')}
        />
      </div>

      <div className={styles.row}>
        <Select
          label="Land"
          options={country_select_options}
          defaultValue={property_owner.country || 'DE'}
          {...register('country')}
        />
        <div className={styles.gutter} />
        <Select
          label="Geschlecht"
          options={gender_select_options}
          defaultValue={property_owner.gender || ''}
          error={errors.gender?.message}
          {...register('gender')}
        />
      </div>

      <CoownerFormSection
        errors={errors}
        register={register}
        watch={watch}
        setValue={setValue}
      />
    </>
  );
}
