import {RPC} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import {redirectExternal} from 'shared/utils.js';

import {handleError} from '../effects.js';

export default function SwanVerifyButton() {
  return <Button title="Profil verifizieren" onClick={proceed} />;
}

async function proceed() {
  let swan_redirect_url;

  try {
    swan_redirect_url = await RPC('getSwanAccountVerificationUrl', {
      final_redirect_url: window.location.toString(),
    });
  } catch (err) {
    handleError(err);
    return;
  }

  redirectExternal(swan_redirect_url);
}
