import React, {forwardRef} from 'react';
import classNames from 'classnames/bind.js';

import text_styles from '../styles/text_styles.module.scss';
import {_} from '../../shared/l10n.js';

import styles from './Select.module.scss';

function Select(
  {className, label, options, error = '', readOnly, ...props},
  ref,
) {
  return (
    <div className={styles.container}>
      {label && (
        <label className={classNames(text_styles.caption_left, styles.label)}>
          {label}
        </label>
      )}
      <div className={classNames(styles.wrapper, className)}>
        <select
          className={classNames(
            text_styles.body2,
            styles.select,
            readOnly && styles.read_only,
          )}
          defaultValue=""
          ref={ref}
          disabled={readOnly}
          {...props}>
          <option disabled value="">
            {_('auswählen')}
          </option>
          {options.map((item) => (
            <option value={item.id} key={item.id}>
              {trucate(item.name)}
            </option>
          ))}
        </select>
        {!readOnly && (
          <p className={classNames(text_styles.caption_left, styles.error)}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
}

// https://github.com/get-momo/issues/issues/3247
export function trucate(str) {
  return str.length < 29 ? str : `${str.slice(0, 26)}…`;
}

export default forwardRef(Select);
