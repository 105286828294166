export function formatPrice(cents) {
  if (!Number.isInteger(cents) || cents < 0) {
    throw new Error('"cents" needs to be a positive integer');
  }

  const formatter = new Intl.NumberFormat('de', {
    style: 'currency',
    currency: 'EUR',
  });
  return formatter.format(+cents / 100);
}
