import {ReactComponent as MomoLogo} from '../../assets/momo_logo.svg';

import styles from './Header.module.scss';

export default function Header() {
  return (
    <header className={styles.container}>
      <MomoLogo className={styles.logo} />
    </header>
  );
}
