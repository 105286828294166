import Footer from './Footer.js';
import Header from './Header.js';
import styles from './SwanLayout.module.scss';

export default function SwanLayout({children}) {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <Header />
        <div className={styles.content}>{children}</div>
      </div>
      <Footer />
    </div>
  );
}
