import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import Input from 'shared/components/Input.js';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {setFormErrors} from 'shared/effects.js';
import Button from 'shared/components/Button.js';
import {formatIban} from 'shared/utils.js';
import Icon from 'shared/components/Icon.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import {MANAGER_PATHS, MANAGER_BANKING_STATUS} from 'shared/constants.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {
  handleError,
  closeDialog,
  showDialog,
  confirmClose,
} from '../../effects.js';
import {updateBankingAccount} from '../../actions.js';
import {useResources} from '../../hooks.js';

import {ReactComponent as HourglassSvg} from './icon-hourglass.svg';
import {ReactComponent as SecureSvg} from './icon-secure.svg';
import {ReactComponent as DownloadSvg} from './icon-download.svg';
import styles from './BankingAccountDialog.module.scss';
import ManagerStatus from './ManagerStatus.js';

export default function BankingAccountDialog({props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const history = useHistory();
  const [banking_account] = useResources('getBankingAccounts', id);
  const {notify} = useNotify();

  const {
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    register,
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      description: banking_account?.description || '',
    },
  });

  useEffect(() => {
    reset(banking_account);
  }, [banking_account, reset]);

  const onSave = handleSubmit((fields) =>
    save({banking_account, fields, setError, setVisible}),
  );

  return (
    <Dialog
      title="Momo-Konto"
      show={visible}
      onHide={() => confirmClose({isDirty})}
      className={styles.container}
      onExited={() => history.push(MANAGER_PATHS.BankingAccountsScreen)}
      {...props}>
      <form className={styles.form}>
        <Input
          label="Kontoinhaber"
          value={banking_account?.property_owner_display_name || '-'}
          readOnly
        />
        <p className={text_styles.caption_left}>Status</p>
        <div className={styles.manager_status}>
          <ManagerStatus manager_status={banking_account?.manager_status} />
        </div>
        {banking_account?.manager_status ===
          MANAGER_BANKING_STATUS['200_access_not_granted'] && (
          <Button
            type="button"
            onClick={() => {
              requestBankingAccountAccess({banking_account, notify});
            }}
            title="Zugriffsanfrage versenden"
            className={styles.request_access_button}
          />
        )}
        <div className={styles.iban_container}>
          <div className={text_styles.caption_left}>IBAN</div>
          <div className={styles.iban}>
            {!banking_account?.iban && (
              <Icon className={styles.icon}>
                <HourglassSvg />
              </Icon>
            )}
            <Input
              value={
                formatIban(banking_account?.iban) || 'Das Konto wird erstellt …'
              }
              inputClassName={styles.iban_input}
              readOnly
            />
          </div>
        </div>
        <Input label="BIC" value={banking_account?.bic || '-'} readOnly />
        <Input
          label="Beschreibung Konto"
          type="text"
          placeholder="..."
          className={styles.margin_right}
          error={errors.description?.message}
          {...register('description')}
        />

        {banking_account?.iban ? (
          <>
            <hr />
            <Button
              type="button"
              onClick={() =>
                showDialog('banking_download_statements', {banking_account})
              }
              title="Transaktionen herunterladen"
              className={styles.download_statement_button}
              Icon={DownloadSvg}
            />
            <hr />
            <div className={styles.number_of_rent_collections}>
              <p className={text_styles.caption_left}>Verknüpfte Mieter</p>
              <div className={styles.row}>
                <Icon className={styles.icon}>
                  <SecureSvg />
                </Icon>
                <p className={text_styles.body2_bold}>Mietverwaltung: </p>
                <p className={classNames(text_styles.body2, styles.number)}>
                  {banking_account?.number_of_rent_collections}
                </p>
              </div>
            </div>
          </>
        ) : (
          <p className={classNames(text_styles.body1_bold_left, styles.note)}>
            Das Konto wird im Hintergrund erstellt, das kann einen Moment
            dauern. Wir senden Ihnen eine E-Mail, sobald das Konto verwendet
            werden kann.
          </p>
        )}
        <Button
          title="Speichern"
          loading={isSubmitting}
          onClick={onSave}
          disabled={!isDirty}
        />
      </form>
    </Dialog>
  );
}

async function save({banking_account, fields, setError, setVisible}) {
  try {
    await updateBankingAccount({
      id: banking_account.id,
      ...fields,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      }
    } else {
      handleError(err);
    }
    return;
  }
  setVisible(false);
}

async function requestBankingAccountAccess({banking_account, notify}) {
  try {
    await RPC('requestBankingAccountAccess', {
      banking_account_id: banking_account.id,
    });
  } catch (err) {
    handleError(err);
    return;
  }

  notify({text: 'Zugriffsanfrage versendet'});
  closeDialog('banking_account');
}
