import React from 'react';
import {useTable} from 'react-table';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import {useSelector} from 'react-redux';
import {BANKING_FEATURE} from 'shared/constants.js';

import Spinner from '../../components/spinner/Spinner.js';
import SwanVerifyButton from '../../components/SwanVerifyButton.js';

import {columns} from './BankingAccountsTable.js';
import table_styles from './BankingAccountsTable.module.scss';
import styles from './BankingAccountsPlaceholder.module.scss';

export default function BankingAccountsPlaceholder({search_query, loading}) {
  const {features} = useSelector((state) => state.user.account);
  const {getTableProps, headerGroups, getTableBodyProps} = useTable({
    columns,
    data: [],
  });
  const placeholder_rows = Array.from({length: 3}, (_, i) => i);

  return (
    <>
      <table className={table_styles.table} {...getTableProps()}>
        <thead className={table_styles.thead}>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                return (
                  <th
                    key={i}
                    className={classNames(
                      table_styles.th,
                      table_styles[column.id],
                    )}
                    {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {placeholder_rows.map((i) => {
            return (
              <tr
                key={i}
                className={classNames(
                  table_styles.tr,
                  table_styles[`placeholder_row__${i}`],
                )}>
                {columns.map((column, i) => {
                  if (column.id === 'actions') return null;

                  return (
                    <td key={i}>
                      <div className={styles.empty}>
                        <div className={table_styles[column.id]} />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className={classNames(text_styles.body2_centered, table_styles.text)}>
        {getPlaceholderText({
          search_query,
          loading,
          enabled_and_not_eligible:
            features.banking === BANKING_FEATURE.enabled_and_not_eligible,
        })}
      </div>
      {features.banking === BANKING_FEATURE.enabled_and_not_eligible && (
        <SwanVerifyButton />
      )}
      {loading && <Spinner className={styles.spinner} />}
    </>
  );
}

const PLACEHOLDER_USER_NOT_ELIGIBLE = `Um Zugriff auf Konten und die Mietverwaltung zu erhalten,
müssen Sie ihr Profil verifizieren.`;

const PLACEHOLDER_TEXT_NONE_CREATED = `Es befinden sich noch keine Konten in dieser Liste.
Sie können Konten per Klick auf „Konto erstellen“ hinzufügen.`;

const PLACEHOLDER_TEXT_NO_MATCHES = `Wir konnten keine Einträge auf Ihre Suchanfrage finden.
Bitte überprüfen Sie Ihre Suchanfrage auf Schreibfehler.`;

const PLACEHOLDER_TEXT_LOADING =
  'Einen Moment Bitte, wir durchsuchen Eintrag...';

function getPlaceholderText({search_query, loading, enabled_and_not_eligible}) {
  if (enabled_and_not_eligible) return PLACEHOLDER_USER_NOT_ELIGIBLE;

  if (loading) {
    return PLACEHOLDER_TEXT_LOADING;
  }
  if (search_query?.length > 0) {
    return PLACEHOLDER_TEXT_NO_MATCHES;
  }
  return PLACEHOLDER_TEXT_NONE_CREATED;
}
