import text_styles from 'shared/styles/text_styles.module.scss';
import {formatIban} from 'shared/utils';
import {useMemo} from 'react';
import Placeholder from 'shared/components/combobox/Placeholder.js';

import Combobox from '../../components/combobox/Combobox.js';
import NewEntryButton from '../../components/combobox/NewEntryButton.js';
import {showDialog} from '../../effects.js';

export default function SelectBankingAccount({
  value,
  onChange,
  selectedItemListener,
  error,
  label,
  readOnly,
  property_owner_id,
}) {
  const rpc_params = useMemo(
    () => ({
      property_owner_id,
    }),
    [property_owner_id],
  );

  return (
    <Combobox
      name="banking_account"
      value={value}
      onChange={onChange}
      selectedItemListener={selectedItemListener}
      placeholder="..."
      rpc_method="getBankingAccountsOptions"
      DropdownItem={DropdownItem}
      itemToString={itemToString}
      error={error}
      button_new={
        <NewEntryButton
          title="Konto erstellen"
          dialog="create_banking_account"
          onChange={onChange}
          dialog_props={{
            property_owner_id,
          }}
          showDialog={showDialog}
        />
      }
      NoResultsPlaceholder={NoResultsPlaceholder}
      NoSearchResultsPlaceholder={NoSearchResultsPlaceholder}
      label={label}
      rpc_params={rpc_params}
      readOnly={readOnly}
      scrollable_search_result={true}
    />
  );
}

function DropdownItem({item}) {
  return (
    <div>
      <p className={text_styles.body1_bold_left}>{produceDisplayText(item)}</p>
      <p className={text_styles.caption_left}>{item.description || '-'}</p>
    </div>
  );
}

function itemToString(item) {
  return item?.id ? produceDisplayText(item) : '';
}

function produceDisplayText({iban, property_owner_display_name}) {
  const iban_text = iban ? formatIban(iban) : 'Das Konto wird erstellt ...';

  return `${iban_text} (${property_owner_display_name})`;
}

function NoResultsPlaceholder() {
  return (
    <Placeholder>
      <p>Es wurden noch keine Konten angelegt. </p>
      <p>
        Sie können per Klick auf „Neues Konto hinzufügen“ das erste Konto
        anlegen und dieses später im Menüpunkt „Konten“ verwalten.
      </p>
    </Placeholder>
  );
}
function NoSearchResultsPlaceholder({search_query}) {
  return (
    <Placeholder>
      <p>Wir konnten keine Konto beginnend mit „{search_query}“ finden.</p>
      <p>
        Sie können per Klick auf „Neues Konto hinzufügen“ das erste Konto
        anlegen und dieses später im Menüpunkt „Konten“ verwalten.
      </p>
    </Placeholder>
  );
}
