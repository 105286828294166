import Button from 'shared/components/Button.js';
import {useForm, Controller} from 'react-hook-form';
import {BAD_REQUEST} from 'shared/api.js';
import Input from 'shared/components/Input.js';
import {setFormErrors} from 'shared/effects.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {useState} from 'react';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {handleError, alert, showDialog, confirmClose} from '../../effects.js';
import SelectRentalContract from '../../components/SelectRentalContract.js';
import {allocateVibanForRentCollection} from '../../actions.js';

import styles from './RentCollectionDialog.module.scss';
import SelectBankingAccount from './SelectBankingAccount.js';

export default function RentCollectionInvitationDialog(props) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();

  const {
    control,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    register,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const [rental_contract, setRentalContract] = useState(null);
  const [banking_account, setBankingAccount] = useState(null);

  const no_banking_account = !banking_account;
  const banking_account_is_inactive = banking_account && !banking_account.iban;
  const banking_account_not_enabled =
    banking_account && !banking_account.account_enabled;
  const submit_disabled =
    !isDirty ||
    no_banking_account ||
    banking_account_is_inactive ||
    banking_account_not_enabled;

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );

  const footer = (
    <div className={styles.footer}>
      <Button
        title="Mieter hinzufügen"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={submit_disabled}
      />
    </div>
  );

  return (
    <Dialog
      title="Mieter hinzufügen"
      show={visible}
      footer={footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div className="">
        <Controller
          control={control}
          name="rental_contract_id"
          defaultValue=""
          render={({field: {value, onChange}}) => {
            return (
              <SelectRentalContract
                label="Hauptmieter"
                value={value}
                rpc_method="getRentCollectionOptions"
                onChange={onChange}
                selectedItemListener={(rental_contract) => {
                  setRentalContract(rental_contract);
                  setValue(
                    'banking_account_id',
                    rental_contract?.banking_account_id || null,
                  );
                }}
                error={errors.rental_contract_id?.message}
              />
            );
          }}
        />
        {rental_contract && (
          <>
            <Controller
              control={control}
              name="banking_account_id"
              selectedItemListener={setBankingAccount}
              render={({field: {value, onChange}}) => {
                return (
                  <SelectBankingAccount
                    label="Konto"
                    value={value}
                    onChange={onChange}
                    selectedItemListener={setBankingAccount}
                    error={errors.banking_account_id?.message}
                    property_owner_id={rental_contract?.property_owner_id}
                    readOnly={!!rental_contract?.banking_account_id}
                  />
                );
              }}
            />
            <Input
              defaultValue={rental_contract?.given_reference}
              type="text"
              label="Beschreibung Wohneinheit"
              placeholder="z. B. OG, rechts …"
              error={errors.given_reference?.message}
              {...register('given_reference')}
            />
            {banking_account_is_inactive && (
              <p className={text_styles.body1_bold_left}>
                Bitte warten Sie bis das Konto erstellt wurde, wir informieren
                Sie per E-Mail.
              </p>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  let res;

  try {
    res = await allocateVibanForRentCollection(fields);
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      } else if (err.message) {
        alert({title: err.message});
      }
    } else {
      handleError(err);
    }
    return;
  }

  notify({text: 'Die Mietverwaltung wurde erstellt'});
  setVisible(false);
  showDialog('rent_collection_success', res);
}
