import React from 'react';
import {useSelector} from 'react-redux';

import ClaimDialog from '../scenes/deposits/ClaimDialog.js';
import DepositGuaranteeInfoDialog from '../scenes/inviteTenant/DepositGuaranteeInfoDialog.js';
import ReleaseDialog from '../scenes/releaseDepositGuarantee/ReleaseDialog.js';
import RentCollectionSuccessDialog from '../scenes/rentCollection/RentCollectionSuccessDialog.js';
import CreateRentalContractDialog from '../components/CreateRentalContractDialog.js';
import CreatePropertyOwnerDialog from '../scenes/propertyOwners/CreatePropertyOwnerDialog.js';
import CreateBankingAccountDialog from '../scenes/bankingAccounts/CreateBankingAccountDialog.js';
import PropertyOwnerDialogReadOnly from '../scenes/propertyOwners/PropertyOwnerDialogReadOnly.js';
import CreateBankingAccountSuccessDialog from '../scenes/bankingAccounts/CreateBankingAccountSuccessDialog.js';
import DownloadStatementsDialog from '../scenes/bankingAccounts/DownloadStatementDialog.js';
import PropertyOwnerEmailDialog from '../scenes/propertyOwners/PropertyOwnerEmailDialog.js';
import CashDepositSuccessDialog from '../scenes/createCashDeposit/CashDepositSuccessDialog.js';

import store from './store.js';

const components = {
  claim: ClaimDialog,
  deposit_guarantee_info: DepositGuaranteeInfoDialog,
  release: ReleaseDialog,
  rent_collection_success: RentCollectionSuccessDialog,
  create_rental_contract: CreateRentalContractDialog,
  create_property_owner: CreatePropertyOwnerDialog,
  create_banking_account: CreateBankingAccountDialog,
  property_owner_read_only: PropertyOwnerDialogReadOnly,
  create_banking_account_success: CreateBankingAccountSuccessDialog,
  banking_download_statements: DownloadStatementsDialog,
  property_owner_email: PropertyOwnerEmailDialog,
  cash_deposit_success: CashDepositSuccessDialog,
};

export default function DialogConnector() {
  const dialogs = useSelector((state) => {
    return state.dialogs;
  });

  return Object.entries(components).map(([name, Component]) => {
    return (
      dialogs[name]?.mounted && (
        <Component
          {...dialogs[name].props}
          key={name}
          dialog_name={name}
          show={dialogs[name].visible}
          onExited={() => {
            store.dispatch({type: 'UNMOUNT_DIALOG', name});
          }}
        />
      )
    );
  });
}
