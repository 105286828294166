import {ReactComponent as MomoLogo} from '../../assets/momo_logo.svg';

import {ReactComponent as SwanLogo} from './swan_logo.svg';
import styles from './Footer.module.scss';

export default function Footer() {
  return (
    <footer className={styles.container}>
      <MomoLogo className={styles.logo} />
      <div className={styles.text_container}>
        <p className={styles.text}>In Partnerschaft mit </p>
        <SwanLogo className={styles.swan_logo} />
      </div>
    </footer>
  );
}
