import {useState} from 'react';
import {useSelector} from 'react-redux';
import {BANKING_FEATURE, MANAGER_PATHS} from 'shared/constants.js';
import {Switch, Route, useHistory} from 'react-router-dom';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import {useFetchRefresh} from '../../hooks.js';

import RentCollectionDialog from './RentCollectionDialog.js';
import RentCollectionPlaceholder from './RentCollectionPlaceholder.js';
import RentCollectionTable from './RentCollectionTable.js';
import styles from './RentCollectionScreen.module.scss';
import RentCollectionInvitationDialog from './RentCollectionInvitationDialog.js';

const per_page = 10;
const default_sort_column = 'created_at';
const default_sort_direction = 'DESC';

export default function RentCollectionScreen() {
  const history = useHistory();
  const {refresh} = useSelector((state) => state.rental_contracts_with_viban);
  const {features} = useSelector((state) => state.user.account);
  const [search_query, setSearchQuery] = useState('');
  const [sort_column, setSortColumn] = useState(default_sort_column);
  const [sort_direction, setSortDirection] = useState(default_sort_direction);

  const {loading, rows, total, offset, setOffset} = useFetchRefresh({
    method: 'getRentCollections',
    search_query,
    per_page,
    refresh,
    sort_column,
    sort_direction,
  });

  function orderBy(field) {
    if (sort_column === field) {
      sort_direction === 'DESC'
        ? setSortDirection('ASC')
        : setSortDirection('DESC');
    } else {
      setSortColumn(field);
      setSortDirection('ASC');
    }
  }

  function resetTable() {
    setSortColumn(default_sort_column);
    setSortDirection(default_sort_direction);
    setOffset(0);
  }

  function onOpenRentCollection(rent_collection) {
    history.push(`${MANAGER_PATHS.RentCollectionScreen}/${rent_collection.id}`);
  }

  function routeToListScreen() {
    history.push(MANAGER_PATHS.RentCollectionScreen);
  }

  const show_table =
    rows.length > 0 &&
    features.banking === BANKING_FEATURE.enabled_and_eligible;

  return (
    <>
      <Layout
        button={
          <ButtonTenantInvitation
            resetTable={resetTable}
            disabled={
              features.banking === BANKING_FEATURE.enabled_and_not_eligible
            }
          />
        }
        search={
          features.banking === BANKING_FEATURE.enabled_and_eligible && (
            <SearchInput onValue={setSearchQuery} />
          )
        }>
        <Switch>
          <Route path={MANAGER_PATHS.RentCollectionInvitationDialog}>
            <RentCollectionInvitationDialog onExited={routeToListScreen} />
          </Route>
          <Route path={`${MANAGER_PATHS.RentCollectionScreen}/:id`}>
            <RentCollectionDialog onExited={routeToListScreen} />
          </Route>
        </Switch>
        <div className={styles.content}>
          {show_table ? (
            <RentCollectionTable
              rental_contracts_with_viban={rows}
              onOpen={onOpenRentCollection}
              orderBy={orderBy}
              sort_column={sort_column}
              sort_direction={sort_direction}
              loading={loading}
              onOpenRentCollection={onOpenRentCollection}
            />
          ) : (
            <RentCollectionPlaceholder
              search_query={search_query}
              loading={loading}
            />
          )}
          {!!total && (
            <Pagination
              total={total}
              offset={offset}
              setOffset={setOffset}
              per_page={per_page}
            />
          )}
        </div>
      </Layout>
    </>
  );
}

function ButtonTenantInvitation({resetTable, disabled}) {
  const history = useHistory();

  return (
    <ButtonAdd
      title="Mieter hinzufügen"
      onClick={() => {
        resetTable();
        history.push(MANAGER_PATHS.RentCollectionInvitationDialog);
      }}
      name="rent_collection_invitation"
      disabled={disabled}
    />
  );
}
