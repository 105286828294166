import {subDays} from 'date-fns';
import {Controller, useForm} from 'react-hook-form';
import {request} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import DateInput from 'shared/components/DateInput.js';
import {setFormErrors} from 'shared/effects.js';
import {useState} from 'react';
import LabeledCircleSpinner from 'shared/components/LabeledCircleSpinner.js';

import Dialog from '../../components/dialog/Dialog.js';
import {closeDialog, handleError, confirmClose} from '../../effects.js';

import styles from './DownloadStatementDialog.module.scss';

export default function DownloadStatementDialog({banking_account, ...props}) {
  const [loading, setLoading] = useState(false);
  const {
    formState: {isSubmitting, isDirty, errors, readOnly},
    handleSubmit,
    setError,
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: null,
    },
  });

  const onDownloadStatements = handleSubmit((fields) =>
    downloadBankingAccountStatements({
      banking_account,
      fields,
      setError,
      setLoading,
    }),
  );

  return (
    <Dialog
      title="Transaktionen Herunterladen"
      onHide={() =>
        confirmClose({
          isDirty,
          afterClose: () => closeDialog('banking_download_statements'),
        })
      }
      className={styles.dialog}
      {...props}>
      <form className={styles.form}>
        <Controller
          name="date"
          control={control}
          defaultValue={null}
          render={({field: {value, onChange, name}}) => (
            <DateInput
              value={value}
              onChange={onChange}
              label="Datum"
              readOnly={readOnly}
              maxDate={subDays(new Date(), 1)}
              error={errors[name]?.message}
              name={name}
              className={styles.date_input}
            />
          )}
        />
        {loading ? (
          <LabeledCircleSpinner />
        ) : (
          <Button
            title="Herunterladen"
            loading={isSubmitting}
            onClick={onDownloadStatements}
            disabled={!isDirty}
            className={styles.button}
          />
        )}
      </form>
    </Dialog>
  );
}

async function downloadBankingAccountStatements({
  banking_account,
  fields,
  setError,
  setLoading,
}) {
  setLoading(true);
  let filename;
  let path;

  const search_parameters = new URLSearchParams(
    Object.entries(fields).filter(
      ([, value]) => value !== null && value !== undefined && value !== '',
    ),
  );

  if (banking_account) {
    search_parameters.set('banking_account_id', banking_account.id);
    filename = `Kontoauszug-${banking_account.iban}-${fields.date}.xml`;
    path = `getBankingAccountPaymentsCamt053?${search_parameters.toString()}`;
  } else {
    filename = `Kontoauszüge-${fields.date}.zip`;
    path = `getAllBankingAccountPaymentsCamt053?${search_parameters.toString()}`;
  }

  try {
    const response = await request(path, {
      method: 'GET',
      credentials: 'include',
    });

    if (response.status === 400) {
      const errors = await response.json();
      if (errors?.length) {
        setFormErrors({
          setError,
          errors,
        });
      }
    }

    if (response.status < 200 || response.status >= 300) {
      throw new Error(
        `An unknown error ${
          response.status
        } occurred while downloading CAMT.053: ${await response.text()}`,
      );
    }

    await downloadStatementFromResponse(response, filename);
  } catch (err) {
    handleError(err);
    return;
  } finally {
    setLoading(false);
  }

  closeDialog('banking_download_statements');
}

async function downloadStatementFromResponse(response, filename) {
  const objectUrl = URL.createObjectURL(await response.blob());
  const a = document.createElement('a');
  a.setAttribute('href', objectUrl);
  a.setAttribute('download', filename);
  a.setAttribute('target', '_blank');
  document.body.append(a);
  a.click();
  a.remove();
}
