export default function initialState() {
  return {
    user: {
      account: null,
      status: 'loading',
      features: {},
    },
    modal: {},
    dialogs: {
      open_dialogs: [],
    },
    deposit_guarantees: {
      // Use this to retrigger fetches
      refresh: 0,
    },
    rental_contracts_with_viban: {
      // Use this to retrigger fetches
      refresh: 0,
    },
    property_owners: {
      refresh: 0,
    },
    banking_accounts: {
      // Use this to retrigger fetches
      refresh: 0,
    },
  };
}
