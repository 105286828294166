import Input from 'shared/components/Input.js';
import SeparatorLine from 'shared/components/SeparatorLine.js';
import className from 'classnames';

import styles from './TenantData.module.scss';

export default function TenantData({errors, register}) {
  return (
    <>
      <div className={styles.row}>
        <Input
          label="Vorname Mieter 1"
          error={errors.tenant_1_first_name?.message}
          {...register('tenant_1_first_name')}
        />
        <Input
          label="Nachname Mieter 1"
          error={errors.tenant_1_last_name?.message}
          {...register('tenant_1_last_name')}
        />
      </div>

      <div className={className(styles.row, styles.second_tenant)}>
        <Input
          label="Vorname Mieter 2 (optional)"
          error={errors.tenant_2_first_name?.message}
          {...register('tenant_2_first_name')}
        />
        <Input
          label="Nachname Mieter 2 (optional)"
          error={errors.tenant_2_last_name?.message}
          {...register('tenant_2_last_name')}
        />
      </div>

      <SeparatorLine />

      <Input
        type="text"
        label="Beschreibung (optional)"
        placeholder="z. B. ID-123"
        error={errors.given_reference?.message}
        {...register('given_reference')}
        className={styles.given_reference}
      />
    </>
  );
}
