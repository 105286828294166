import React from 'react';
import text_styles from 'shared/styles/text_styles.module.scss';
import IconButton from 'shared/components/IconButton.js';
import {ReactComponent as CopySvg} from 'shared/assets/copy.svg';
import {copyText} from 'shared/effects.js';

import styles from './TextFieldWithCopy.module.scss';

export default function TextFieldWithCopy({label, text, copyLabel}) {
  return (
    <div>
      <p className={text_styles.caption_left}>{label}</p>
      <div className={styles.wrapper}>
        <p className={text_styles.body1_bold_left}>{text}</p>
        <IconButton
          onClick={() => copyText(copyLabel, text)}
          className={styles.copy_icon}>
          <CopySvg />
        </IconButton>
      </div>
    </div>
  );
}
