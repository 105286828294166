import Button from 'shared/components/Button.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import SeparatorLine from 'shared/components/SeparatorLine';
import {formatIban} from 'shared/utils.js';

import {closeDialog} from '../../effects.js';
import Dialog from '../../components/dialog/Dialog.js';

import styles from './RentCollectionSuccessDialog.module.scss';
import TextFieldWithCopy from './TextFieldWithCopy.js';

export default function RentCollectionSuccessDialog({
  iban,
  property_owner_display_name,
  viban,
  bic,
  ...props
}) {
  const footer = (
    <div className={styles.footer}>
      <Button
        title="Fertig"
        onClick={() => closeDialog('rent_collection_success')}
      />
    </div>
  );

  return (
    <Dialog title="Nächste Schritte" footer={footer} {...props}>
      <div className={styles.info_container}>
        <p
          className={classNames(
            text_styles.h1_bright_bold_centered,
            styles.info_header,
          )}>
          Herzlichen Glückwunsch
        </p>
        <p className={text_styles.body1_bright_centered}>
          Die Mietverwaltung wurde erfolgreich eingerichtet.
        </p>
      </div>

      <p className={classNames(text_styles.body1_bold_left, styles.text)}>
        Bitte verwenden Sie diese virtuelle IBAN für das Mietverhältnis.
      </p>

      <div className={styles.row}>
        <TextFieldWithCopy
          label="Virtuelle IBAN:"
          text={formatIban(viban)}
          copyLabel="Virtuelle IBAN"
        />
        <TextFieldWithCopy label="BIC:" text={bic} copyLabel="BIC" />
      </div>
      <TextFieldWithCopy
        label="Zahlungsempfänger:"
        text={property_owner_display_name}
        copyLabel="Zahlungsempfänger"
      />

      <SeparatorLine />

      <p className={text_styles.body2}>
        Das vom Mieter an die virtuelle IBAN gesendete Geld wird auf das Konto{' '}
        {formatIban(iban)} des Zahlungsempfängers gesendet.
      </p>
    </Dialog>
  );
}
