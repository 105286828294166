import {createStore, combineReducers} from 'redux';
import {devToolsEnhancer} from 'redux-devtools-extension';
import modalReducer from 'shared/store/modalReducer.js';

import initialState from './initialState.js';
import userReducer from './userReducer.js';
import dialogsReducer from './dialogsReducer.js';
import depositGuaranteesReducer from './depositGuaranteesReducer.js';
import rentCollectionsReducer from './rentCollectionsReducer.js';
import propertyOwnersReducer from './propertyOwnersReducer.js';
import bankingAccountsReducer from './bankingAccountsReducer.js';

const appReducer = combineReducers({
  user: userReducer,
  modal: modalReducer,
  dialogs: dialogsReducer,
  deposit_guarantees: depositGuaranteesReducer,
  rental_contracts_with_viban: rentCollectionsReducer,
  banking_accounts: bankingAccountsReducer,
  property_owners: propertyOwnersReducer,
});

function rootReducer(state, action) {
  if (action.type === 'RESET') {
    state = initialState();
  }
  return appReducer(state, action);
}

export default createStore(rootReducer, initialState(), devToolsEnhancer());
