import Button from 'shared/components/Button.js';
import {useForm} from 'react-hook-form';
import {BAD_REQUEST} from 'shared/api.js';
import {formatDate, formatShortId} from 'shared/utils.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {setFormErrors} from 'shared/effects.js';
import IconButton from 'shared/components/IconButton.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useEffect, useState} from 'react';
import {useNotify} from 'shared/NotifyProvider.js';

import {handleError, confirm, confirmClose} from '../../effects.js';
import {deleteRentCollection, updateRentalContract} from '../../actions.js';
import {ReactComponent as TrashSvg} from '../../assets/trash.svg';
import {useResources} from '../../hooks.js';

import styles from './RentCollectionDialog.module.scss';
import BasicInformationSection from './BasicInformationSection.js';

export default function RentCollectionDialog(props) {
  const {id} = useParams();
  const [visible, setVisible] = useState(true);
  const [rent_collection] = useResources('getRentCollections', id);
  const {notify} = useNotify();

  const {
    control,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    reset,
    register,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    reset(rent_collection);
  }, [rent_collection, reset]);

  const onSubmit = handleSubmit((fields) =>
    proceed({rent_collection, fields, setError, reset, setVisible, notify}),
  );

  function TrashElement() {
    return (
      <IconButton
        onClick={() =>
          onDelete({rent_collection, notify}).then((didDelete) => {
            if (didDelete) setVisible(false);
          })
        }>
        <TrashSvg />
      </IconButton>
    );
  }

  const footer = (
    <div className={styles.footer}>
      <Button
        title="Speichern"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </div>
  );

  return (
    <Dialog
      title="Konfiguration"
      show={visible}
      footer={footer}
      additionalIcons={[TrashElement]}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div>
        <BasicInformationSection
          control={control}
          register={register}
          errors={errors}
          rent_collection={rent_collection}
        />
      </div>
      <div className={styles.details}>
        <div className={text_styles.caption_left}>
          Erstellt am{' '}
          {rent_collection ? formatDate(rent_collection.created_at) : ''}
        </div>
        <div className={text_styles.caption_left}>
          Momo-ID: {formatShortId(rent_collection?.short_id)}
        </div>
      </div>
    </Dialog>
  );
}

async function proceed({
  rent_collection,
  fields,
  setError,
  setVisible,
  notify,
}) {
  const {warm_rent_cents, given_reference} = fields;

  try {
    await updateRentalContract({
      id: rent_collection.id,
      warm_rent_cents,
      given_reference,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  notify({
    text: 'Die Änderungen wurden gespeichert',
  });

  setVisible(false);
}

async function onDelete({rent_collection, notify}) {
  const {tenant_display_name, id} = rent_collection;

  if (
    !(await confirm({
      title: `Möchten Sie ${tenant_display_name} wirklich endgültig aus der Liste entfernen?`,
      text: 'Wenn Sie diesen Mieter entfernen, wird der Eintrag aus der Liste gelöscht und die virtuelle IBAN geht verloren.',
      proceed_label: 'Jetzt löschen',
    }))
  ) {
    return;
  }

  try {
    await deleteRentCollection({rental_contract_id: id});
  } catch (err) {
    handleError(err);
    return;
  }

  notify({text: 'Der Mieter wurde gelöscht.'});
  return true;
}
