import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {BANKING_FEATURE, MANAGER_PATHS} from 'shared/constants.js';
import ModalConnector from 'shared/components/modal/ModalConnector.js';

import LoginScreen from './scenes/authentication/LoginScreen.js';
import DepositsScreen from './scenes/deposits/DepositsScreen.js';
import BankingAccountsScreen from './scenes/bankingAccounts/BankingAccountsScreen.js';
import PropertyOwnersScreen from './scenes/propertyOwners/PropertyOwnersScreen.js';
import ResetPasswordScreen from './scenes/authentication/ResetPasswordScreen.js';
import RequestResetPasswordScreen from './scenes/authentication/RequestResetPasswordScreen.js';
import RentCollectionScreen from './scenes/rentCollection/RentCollectionScreen.js';
import AcceptBankingAccountMembershipScreen from './scenes/acceptBankingAccountMembership/AcceptBankingAccountMembershipScreen.js';
import DialogConnector from './store/DialogConnector.js';
import InvitationScreen from './scenes/authentication/InvitationScreen.js';
import IntegrationsScreen from './scenes/integrations/IntegrationsScreen.js';

export default function Router() {
  const account = useSelector((state) => state.user.account);

  return (
    <BrowserRouter>
      <DialogConnector />
      <ModalConnector />
      <Switch>
        <Route path={`${MANAGER_PATHS.InvitationScreen}/:rental_contract_id?`}>
          <InvitationScreen />
        </Route>
        <Route path={MANAGER_PATHS.RequestResetPasswordScreen}>
          <RequestResetPasswordScreen />
        </Route>
        <Route path={MANAGER_PATHS.ResetPasswordScreen}>
          <ResetPasswordScreen />
        </Route>
        <Route path={MANAGER_PATHS.AcceptBankingAccountMembershipScreen}>
          <AcceptBankingAccountMembershipScreen />
        </Route>
        <PrivateRoute path={MANAGER_PATHS.PropertyOwnersScreen}>
          <PropertyOwnersScreen />
        </PrivateRoute>
        {account?.features.banking !== BANKING_FEATURE.disabled && (
          <PrivateRoute path={MANAGER_PATHS.BankingAccountsScreen}>
            <BankingAccountsScreen />
          </PrivateRoute>
        )}
        {account?.features.banking !== BANKING_FEATURE.disabled && (
          <PrivateRoute path={MANAGER_PATHS.RentCollectionScreen}>
            <RentCollectionScreen />
          </PrivateRoute>
        )}
        <PrivateRoute path={`${MANAGER_PATHS.DepositsScreen}/:deposit_id?`}>
          <DepositsScreen />
        </PrivateRoute>

        <Route path={MANAGER_PATHS.IntegrationsScreen}>
          <IntegrationsScreen />
        </Route>

        <Route path="/*">
          <Redirect to={MANAGER_PATHS.DepositsScreen} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function PrivateRoute({children, ...props}) {
  const status = useSelector((state) => state.user.status);

  if (status === 'loading') return null;

  return (
    <Route
      {...props}
      render={() => (status === 'logged_in' ? children : <LoginScreen />)}
    />
  );
}
