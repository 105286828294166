import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';
import {
  BAD_REQUEST,
  FORBIDDEN,
  MANAGER_PROFILE_INCOMPLETE,
} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import {setFormErrors} from 'shared/effects.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import TextArea from 'shared/components/TextArea.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useHistory} from 'react-router-dom';
import {MANAGER_PATHS, VALIDATION_ERROR} from 'shared/constants.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {createBankingAccount} from '../../actions.js';
import {translatePermissionReason} from '../../lib/utils.js';
import {
  handleError,
  alert,
  showDialog,
  showGenericError,
  closeDialog,
  confirmClose,
} from '../../effects.js';
import SelectPropertyOwner from '../../components/SelectPropertyOwner.js';

import styles from './CreateBankingAccountDialog.module.scss';

export default function CreateBankingAccountDialog({
  onSave,
  property_owner_id = null,
  description = '',
  ...props
}) {
  const history = useHistory();
  const {notify} = useNotify();

  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      property_owner_id,
      description: '',
    },
  });

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, onSave, setError, history, notify}),
  );

  useEffect(() => {
    reset({property_owner_id, description}, {keepDefaultValues: true});
  }, [property_owner_id, description, reset]);

  return (
    <Dialog
      title={'Konto erstellen'}
      className={styles.dialog}
      onHide={() => confirmClose({isDirty})}
      onExited={() => {
        history.push(MANAGER_PATHS.BankingAccountsScreen);
      }}
      {...props}>
      <Controller
        control={control}
        name="property_owner_id"
        render={({field: {value, onChange}}) => {
          return (
            <SelectPropertyOwner
              label="Eigentümer"
              value={value}
              onChange={onChange}
              error={errors.property_owner_id?.message}
              readOnly={!!property_owner_id}
            />
          );
        }}
      />

      <TextArea
        label="Beschreibung Konto"
        error={errors.description?.message}
        maxLength="100"
        multiline={false}
        {...register('description')}
      />

      <p className={classNames(styles.clue, text_styles.body1_bold_left)}>
        Wir senden dem Eigentümer per E-Mail einen Link zur Einrichtung des
        Kontos und der Vollmacht.
      </p>

      <Button
        title="Einladung per E-Mail senden"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </Dialog>
  );
}

async function proceed({fields, onSave, setError, history, notify}) {
  let banking_account;

  try {
    banking_account = await createBankingAccount(fields);
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.message === VALIDATION_ERROR.email_is_missing) {
        history.push(MANAGER_PATHS.BankingAccountsScreen);
        showDialog('property_owner_email', {
          property_owner_id: fields.property_owner_id,
          property_owner_display_name: err.data.property_owner_display_name,
          description: fields.description,
        });
      } else if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      } else {
        alert({
          title: 'Die Angaben sind unvollständig',
          text: 'Bitte tragen Sie alle Daten ein. Sollten Ihnen verbindliche Angaben fehlen, können Sie den Vorgang abbrechen und das „neue Konto“ später hinzufügen.',
        });
      }
    } else if (err.code === FORBIDDEN) {
      const {message} = err;
      alert({
        title: 'Das geht leider nicht',
        text: translatePermissionReason(message),
      });
    } else if (err.code === MANAGER_PROFILE_INCOMPLETE) {
      showGenericError();
    } else {
      handleError(err);
    }
    return;
  }
  notify({
    text: 'Die Einladung wurde versendet',
  });
  showDialog('create_banking_account_success');
  if (!onSave) {
    history.push(MANAGER_PATHS.BankingAccountsScreen);
    return;
  }
  await onSave(banking_account.id);
  closeDialog('create_banking_account');
}
