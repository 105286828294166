import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'RENTAL_CONTRACT_UPDATED':
    case 'RENT_COLLECTION_DELETED':
    case 'VIBAN_ALLOCATED': {
      draft.refresh++;
      break;
    }
  }
}, {});
